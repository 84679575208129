<template>
  <div class="ClientsModal">
    <h4>{{ $t("clients.modal.client") }} :</h4>
    <div class="row">
      <div class="col-6 caption">{{ $t("clients.modal.title") }}:</div>
      <div class="col-6">{{ data.title }}</div>
    </div>
    <div class="row">
      <div class="col-6 caption">{{ $t("clients.modal.alias") }}:</div>
      <div class="col-6">{{ data.alias }}</div>
    </div>
    <div class="row">
      <div class="col-6 caption">{{ $t("clients.modal.phone") }}:</div>
      <div class="col-6">{{ data.phone }}</div>
    </div>
    <div class="row">
      <div class="col-6 caption">{{ $t("clients.modal.email") }}:</div>
      <div class="col-6">{{ data.email }}</div>
    </div>
    <div class="row" v-if="deploy === DEPLOYED">
      <div class="col-6 caption">{{ $t("clients.modal.expand") }}:</div>
      <div class="col-6">
        {{ timeStart }}
      </div>
    </div>
    <div class="row" v-if="deploy === DEPLOYED">
      <div class="col-6 caption">{{ $t("clients.modal.author") }}:</div>
      <div class="col-6">
        {{ data.creator.username }}
      </div>
    </div>
    <hr v-if="deploy === NOT_DEPLOYED" />
    <button
      v-if="deploy === NOT_DEPLOYED"
      class="btn btn-primary save expand"
      :disabled="isDisabled"
      @click="startDeploy"
    >
      {{ $t("clients.modal.expand_account") }}
    </button>
  </div>
</template>
<script>
import { STATUS_ACTIVE, NOT_DEPLOYED, DEPLOYED } from "@/utils/clients";
import Api from "@/api/v1/clients";

export default {
  name: "clients-modal",
  props: {
    id: Number,
    data: Object,
  },
  data() {
    return {
      isDisabled: false,
      timeStart: "",
      STATUS_ACTIVE,
      NOT_DEPLOYED,
      DEPLOYED,
      options: {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      },
      deploy: null,
    };
  },
  methods: {
    async startDeploy() {
      this.isDisabled = true;
      const response = await Api.deploy({
        alias: this.data.alias,
        id: this.data.id,
      });

      if (response.data === "ok") {
        this.deploy = DEPLOYED;
        this.$emit("updateTable");
      }
    },
    getTimeStart() {
      if (this.data.time_start !== null) {
        this.timeStart = new Date(this.data.time_start * 1000).toLocaleString(
          "ru-RU",
          this.options
        );
      }
    },
  },
  created() {
    this.deploy = this.data.deploy;
    this.getTimeStart();
  },
};
</script>
