import clients from './clients.js'
import users from './users.js'

let systemName = process.env.VUE_APP_SHOP;

export default {
  clients,
  users,
  month: {
    0: 'Январь',
    1: 'Февраль',
    2: 'Март',
    3: 'Апрель',
    4: 'Май',
    5: 'Июнь',
    6: 'Июль',
    7: 'Август',
    8: 'Сентябрь',
    9: 'Октябрь',
    10: 'Ноябрь',
    11: 'Декабрь',
  },

  login: {
    welcome: 'Добро пожаловать в ' + systemName,
    signIn: 'Вход',
    signUp: 'Регистрация',
    forgot: 'Забыли пароль?',
    labels: {
      email: 'Электронная почта',
      name: 'Имя',
      surname: 'Фамилия',
      password: 'Пароль',
      passwordRepeat: 'Повторите пароль',
      acceptTerms: 'Принимаю условия соглашения',
    }
  },

  main: {
    nav: {
      users: 'Пользователи',
      clients: 'Клиенты',
    },

    load_file: 'Выбрать файл для загрузки.',
    load_files: 'Выбрать файлы для загрузки.',
    seo_title: "SEO title",
    seo_description: "SEO description",
    seo_keywords: "SEO keywords",
    og_site_name: "OG site name",
    og_title: "OG title",
    og_type: "OG type",
    og_image: "OG image",
    og_url: "OG url",
    og_description: "OG description",
    help: 'Помощь',
    selectAll: 'Выбрать все',
    toHome: 'На главную',
    back: 'Назад',
    cancel: 'Отмена',
    close: 'Закрыть',
    save: 'Сохранить',
    delete: 'Удалить',
    search: 'Найти',
    setParams: 'Установить критерии',
    clean: 'Очистить',
    noResults: 'Не найдено',
    noData: 'Нет данных',
    startTyping: 'Начните печатать...',
    pickAValue: 'Выберите значение',
    pickAValueMin: 'Выбрать',
    deselectAValue: 'Удалите значение',
    selectedLabel: 'Выбрано',
    pickAValues: 'Выберите значения',
    searchOrPickAValues: 'Ищите или выберите значения',
    selectLimitText: 'и еще {count}',
    create: 'Добавить',
    update: 'Редактировать',
    view: 'Просмотр',
    menu: {
      logout: 'Выход',
      users: 'Пользователи',
      profile: 'Профиль',
    },
    meta: {
      clients: 'Клиенты',
      employees: 'Сотрудники',
      login: 'Авторизация',
      error: 'Ошибка',
    },
    confirm: {
      title: 'Вы уверены?',
      delete: 'Данные будут удалены без возможности восстановления',
      cancel: 'Данные не сохранятся',
      yes: 'Да',
      no: 'Нет',
    },
    notifications: {
      title: 'Уведомление ' + systemName,
      saved: 'Данные успешно сохранены',
      created: 'Запись успешно добавлена',
      updated: 'Данные успешно изменены',
      deleted: 'Запись успешна удалена',
      passwordChanged: 'Пароль успешно изменен',
      timeout: 'Превышено время ожидания ответа сервера',
      taskQueued: 'Задача поставлена в очередь и будет выполнена в ближайшее время',
    },
    error: {
      serverError: 'Возникла ошибка сервера',
      accessDenied: 'Недостаточно прав для выполнения данного действия',
      occurred: 'Возникла ошибка',
      contactUs: 'Сообщите администратору об ошибке. Спасибо.',
      notFound: 'Страница не найдена',
      noInformation: 'Нет данных',
    },
  },
  table: {
    loading: 'Загрузка ...',
    count: 'Записи с {from} по {to} из {count}|Всего записей: {count}|Одна запись',
    noResults: 'Записи не найдены',
    first: 'Первая',
    last: 'Последняя',
    filter: "Фильтр:",
    filterPlaceholder: "Введите запрос ...",
    limit: "Записей:",
    page: "Страница:",
    filterBy: "Фильтровать по {column}",
    defaultOption: 'Выбрать {column}',
    columns: 'Столбцы'
  },
};
