<template>
  <div class="auth animated fadeInDown">
    <div class="logo text-center">
      <span>MPDESK</span>
    </div>
    <form
      id="sign-in-form"
      @submit.prevent="onSubmit"
      @reset.prevent="onReset"
      @keydown="form.errors.clear()"
    >
      <div class="form-group">
        <input
          type="email"
          class="form-control"
          :state="form.isValid('login')"
          :placeholder="$t('login.labels.email')"
          v-model="form.login"
        />
        <div class="invalid-feedback">
          {{ form.errors.get("login") }}
        </div>
      </div>

      <div class="form-group">
        <input
          type="password"
          class="form-control"
          v-model="form.password"
          :placeholder="$t('login.labels.password')"
        />
        <div class="invalid-feedback">
          {{ form.errors.get("password") }}
        </div>
      </div>

      <button type="submit" class="btn btn-primary submit">
        {{ $t("login.signIn") }}
      </button>
    </form>
  </div>
</template>

<script>
import Form from "@/utils/Form";
import { USER_REQUEST } from "@/store/modules/user/actions";
import { AUTH_REQUEST } from "../store/modules/auth/actions";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      form: new Form({
        login: "",
        password: "",
      }),
    };
  },
  computed: {
    ...mapGetters({
      profile: "getProfile",
    }),
  },
  methods: {
    async onSubmit() {
      try {
        await this.$store.dispatch(AUTH_REQUEST, this.form.data);

        if (this.$store.getters.isAuthenticated) {
          await this.$store.dispatch(USER_REQUEST);
          //   if (!this.$store.getters.withoutRight) {
          //     await this.$store.dispatch(AUTH_LOGOUT);
          //     this.$router.push("/");
          //     this.$notify({
          //       type: "warn",
          //       title: this.$t("main.notifications.title"),
          //       text: "Доступ запрещён",
          //     });
          //   }

          this.$router.push({
            path: "/",
          });
        }
      } catch (error) {
        this.form.onFail(error.response.data);
      }
    },
    onReset() {
      this.form.reset();
    },
  },
  async mounted() {},
};
</script>
