<template>
  <div class="ModalComp"  @click="handleClickOutside">
    <div class="body" ref="modal">
      <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
      <button type="button" class="btn-close" @click="$emit('close')"></button>
      <div class="modal-body">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "modal-comp",
  emits: ["close"],
  props: {
    title: {
      default: "",
    },
  },
  methods: {
    handleClickOutside(event) {
      const modal = this.$refs.modal;
      if (modal != null) {
        if (!modal.contains(event.target)) {
          this.$emit("close");
        }
      }
    },
  },
};
</script>
