export default {
    form: {
        title: 'Название',
        alias: 'Алиас',
        timezone: 'Временная зона',
        phone: 'Номер телефона',
        email: 'Электронная почта',
        tariff: 'Тариф',
        tariff_price: 'Стоимость тарифа',
        time_start: 'Начало',
        status: {
            title: 'Статус',
            active: 'Активный',
            not_active: 'Не активный',
        },

        deploy: 'Развернут',
        expand_account: 'Развернуть аккаунт',
        active: 'Активный',
        not_active: 'Не активный',
        placeholder: {
            title: 'ChiedoCover',
            alias: 'MPDESK',
            timezone: 'Europe/Moscow',
            phone: '+79996753535',
            tariff: '',
            tariff_price: ''
        }
    },
    modal: {
        client: 'Клиент',
        title: 'Название',
        alias: 'Алиас',
        phone: 'Телефон',
        email: 'Электронная почта',
        expand: 'Действует с',
        expand_account: 'Развернуть аккаунт',
        author: 'Автор'
    },
    table: {
        title: 'Клиент',
        status: 'Статус',
        0: 'Не активный',
        1: 'Активный',
    }
}