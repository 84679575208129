<template>
  <div class="sidebar" :class="{ collapsed: collapsed }">
    <div class="menu-open">
      <span
        v-if="collapsed === true"
        @click="handleSidebar(false)"
        class="micon micon__chevron-double-right"
      ></span>
    </div>

    <div class="logo text-center">
      <logo-comp></logo-comp>
      <span
        @click="handleSidebar(true)"
        class="micon micon__chevron-double-left"
      ></span>
    </div>
    <div class="items-ap">
      <div class="item-ap" @click="linkTo('')">
        <span class="micon micon__users"></span>
        <span class="title">{{ $t("main.nav.users") }}</span>
      </div>

      <div class="item-ap" @click="linkTo('clients')">
        <span class="micon micon__employee"></span>
        <span class="title">{{ $t("main.nav.clients") }}</span>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import LogoComp from "@/components/LogoComp";
export default {
  name: "simple-sidebar",
  props: {
    collapsed: {
      default: false,
    },
  },
  data() {
    return {};
  },
  components: {
    LogoComp,
  },

  methods: {
    handleSidebar(value) {
      this.$emit("collapsed", value);
    },
    linkTo(link) {
      this.$router.push("/" + link);
    },
  },
  async created() {},
};
</script>
