export default {
    login: {
        welcome: 'Welcome to AkratoPRIME Edu',
        signIn: 'Sign in',
        signUp: 'Sign up',
        forgot: 'Forgot password?',
        labels: {
            email: 'Email',
            name: 'Name',
            surname: 'Surname',
            password: 'Password',
            passwordRepeat: 'Repeat password',
            acceptTerms: 'I accept terms of use',
        }
    },
    forgot: {
        passwordRecovery: 'Password recovery',
        login: 'Sign in',
        send: 'Send',
        waitForInstruction: 'Password recovery instructions was sent to your emil',
    },
    reset: {
        resetPassword: 'Reset password',
        reset: 'Reset',
    },
    main: {
        toHome: 'To the main page',
        back: 'Go back',
        dump: 'Dump',
        cancel: 'Cancel',
        menu: {
            logout: 'Logout',
            projects: 'Projects',
            bloggers: 'Bloggers',
            clients: 'Clients',
            employees: 'Employees',
        },
        meta: {
            projects: 'Projects',
            bloggers: 'Bloggers',
            clients: 'Clients',
            client: 'Client',
            employees: 'Employees',
            login: 'Login',
            forgot: 'Forgot',
            reset: 'Reset',
            error: 'Error',
        },
        confirm: {
            title: 'Are you sure?',
            delete: 'Data will be deleted forever',
            yes: 'Yes',
            no: 'No',
        },
        notifications: {
            title: 'Carrot CRM notification',
            saved: 'Data successfully saved',
            created: 'Record created',
            updated: 'Data successfully updated',
            deleted: 'Successfully deleted',
            passwordChanged: 'Password was successfully changed',
            timeout: 'Server timeout error',
        },
        error: {
            occurred: 'Unexpected error',
            contactUs: 'Please tell administrator about this error. Thank you.',
            notFound: 'Page not found',
        },
    },
    table: {
        loading: 'Loading ...',
        count: 'Showing {from} to {to} of {count} records|{count} records|One record',
        noResults: 'No matching records',
        first: 'First',
        last: 'Last',
        filter: "Filter:",
        filterPlaceholder: "Search query",
        limit: "Records:",
        page: "Page:",
        filterBy: "Filter by {column}",
        defaultOption: 'Select {column}',
        columns: 'Columns',
    },
    employees: {
        heading: 'Employees',
        create: 'Add employee',
        update: 'Edit employee',
        info: 'Employee info',
        id: '#',
        bio: 'Surname name',
        position: 'Position',
        email: 'E-mail',
        phone: 'Phone',
        status: 'Status',
        active: 'Working',
        inactive: 'Fired',
        search: 'Search by bio',
        activeOnly: 'Only active employees',
        name: 'Name',
        surname: 'Surname',
        patronymic: 'Patronymic',
        password: 'Password',
    },
    clients: {
        heading: 'Clients',
        create: 'Add client',
        update: 'Edit client',
        id: '#',
        name: 'Client',
        brands: 'Brands',
        categories: 'Categories',
        budget: 'Total budget',
        expenses: 'Total expenses',
        marginality: 'Marginality',
        commission: 'Above commission',
        entity: 'Legal entity',
        address: 'Address',
        search: {
            name: 'Search by client',
            brand: 'Search by brand',
            category: 'Search by category',
        },
        brand: 'Brand',
        category: 'Category',
        addBrand: 'Add brand',
        projects: 'Client projects',
    },
    client: {
        marginality: 'Marginality',
        commission: 'Commission',
        budget: 'Projects total budget',
        address: 'Address',
        entity: 'Legal entity',
        updatedBy: 'edited by %{by}, %{at}',
    },
    projects: {
        heading: 'Projects',
    },
    bloggers: {
        heading: 'Bloggers',
        create: 'Create blogger',
        search: {
            name: 'Search by blogger',
            city: 'Search by city',
            tags: 'Search by tags',
            age: 'Search by TA age',
            instCount: 'Instagram subscribers count',
            youtubeCount: 'YouTube subscribers count',
        },
    },
};